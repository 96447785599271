import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type DataState = {
  isLoading: boolean;
  error: any;
};

const initialState: DataState = {
  isLoading: false,
  error: null,
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    loginError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setPasswordSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    setPasswordError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    signUpSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    signUpError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default AuthSlice.reducer;
