import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Filters {
  date__gte?: string;
  date__lte?: string;
  user?: string | number;
  violations?: string | boolean;
  form?: string | boolean;
  ordering?: string | boolean;
}

export type DataState = {
  error: any;
  dailyLogSummary: any[];

  filters: Filters;

  isLoadingLogs: boolean;
  isRefreshData: boolean;

  dataTotalCount: number;
  dataCurrentCount: number;
};

const initialState: DataState = {
  error: null,
  dailyLogSummary: [],

  filters: {},

  isLoadingLogs: false,
  isRefreshData: false,

  dataTotalCount: 0,
  dataCurrentCount: 0,
};

export const DailyLogSummarySlice = createSlice({
  name: 'dailyLogSummary',
  initialState,
  reducers: {
    loadLogs(state, action: PayloadAction<any>) {
      state.dailyLogSummary = action.payload;
    },
    setLoadingLogs(state, action: PayloadAction<any>) {
      state.isLoadingLogs = action.payload;
    },

    setRefreshData(state, action: PayloadAction<any>) {
      state.isRefreshData = action.payload;
    },

    setTotalCountData(state, action: PayloadAction<any>) {
      state.dataTotalCount = action.payload;
    },
    setCurrentCountData(state, action: PayloadAction<any>) {
      state.dataCurrentCount = action.payload;
    },

    setFilters(state, action: PayloadAction<any>) {
      state.filters = action.payload;
    }
  },
});

export default DailyLogSummarySlice.reducer;
