import { AppDispatch } from '../index';
import API from '@/app/lib/axios';

import { urls } from '../urls';

import { UserSlice } from '../controllers/UserSlice';
import { UserAccount, UserCreate, UserSettings } from '@/shared/models/user.model';
import { toast } from 'react-toastify';


export const loadUser = () => async (dispatch: AppDispatch) => {
  try {
    const URL = urls.user;
    const response: UserAccount | undefined = await API.get(`${URL}`);
    dispatch(UserSlice.actions.loadUserSuccess(response));

    return response;
  } catch (err: any) {
    dispatch(UserSlice.actions.loadUserError(err.message));
    console.error(`Error upon Load User: ${err}`);
  }
};

export const loadUserById = (id: string) => async () => {
  try {
    const URL = urls.userById.replace(':id', `${id}`);
    const response = await API.get(`${URL}`);
    return response;
  } catch (err: any) {
    console.error(`Error upon Load User By Id: ${err}`);
  }
};

export const createUser = (data: UserCreate) => async () => {
  try {
    const URL = urls.users;
    const response: any = await API.post(`${URL}`, data);
    return response;
  } catch (err: any) {
    toast(`${err}`);
    console.error(`Error upon User Creating: ${err}`);
  }
};

export const updateUserSettings = (userId: string, data: UserSettings) => async () => {
  try {
    const URL = urls.userById.replace(':id', userId);
    const response = await API.put(`${URL}`, data);
    return response;
  } catch (err: any) {
    console.error(`Error upon Update User: ${err}`);
  }
};

export const updateUserData = (userId: string, data: any) => async () => {
  try {
    const URL = urls.userById.replace(':id', userId);
    const response = await API.patch(`${URL}`, data);
    return response;
  } catch (err: any) {
    console.error(`Error upon Update User: ${err}`);
  }
};

export const updateUser = (data: UserAccount) => async (dispatch: AppDispatch) => {
  try {
    const URL = urls.user;
    const response = await API.put(`${URL}`, data);
    dispatch(UserSlice.actions.loadUserSuccess(response));
  } catch (err: any) {
    toast(`${err}`);
    dispatch(UserSlice.actions.loadUserError(err));
    console.error(`Error upon Update User: ${err}`);
  }
};

export const loadOdometerUnits = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(UserSlice.actions.setLoadingOdometerUnits(true));
    const URL = urls.odometerUnits;
    const response: any = await API.get(`${URL}`);
    dispatch(UserSlice.actions.loadOdometerUnits(response));
    return response;
  } catch (err: any) {
    toast(`${err.message}`);
    console.error(`Error upon Getting Odometer Units: ${err}`);
  } finally {
    dispatch(UserSlice.actions.setLoadingOdometerUnits(false));
  }
};