import { createTheme } from '@mui/material/styles';

export const COLORS = {
  primary: '#2E87CA',
  primaryDark: 'rgba(46, 135, 202, .2)',
  secondary: '#626B82',
  secondaryDark: '#333333',
  grey: '#A3ADB5',
  grey1: '#F3F6F7',
  grey2: '#E3E7EA',
  white: '#FFFFFF',
  black: '#000000',
  green: '#4CAF50',
  red: '#d9534f',
};

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary,
      dark: COLORS.primaryDark,
    },
    secondary: {
      main: COLORS.secondary,
      dark: COLORS.secondaryDark,
    },
    background: {
      default: COLORS.grey,
      paper: COLORS.white,
    },
    // info: {
    //   main: '',
    // },
    // error: {
    //   main: '',
    //   dark: '',
    //   light: ''
    // },
    // success: {
    //   main: '',
    // },
    // common: {
    //   black: '',
    //   white: '',
    // },
  },
  typography: {
    fontFamily: 'OpenSans',
    h1: {
      color: COLORS.primary,
      fontWeight: 600,
      fontSize: 40,
      lineHeight: '48px',
    },
    h2: {
      color: COLORS.primary,
      fontSize: 32,
      fontWeight: 500,
      lineHeight: '24px',
    },
    h3: {
      color: COLORS.primary,
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '24px',
      textAlign: 'left',
    },
    h4: {
      color: COLORS.primary,
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
    },
    h5: {
      color: COLORS.primary,
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '24px',
    },
    h6: {
      color: COLORS.primary,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '20px',
      textAlign: 'left',
    },
    subtitle1: {
      color: COLORS.primary,
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
    },
    subtitle2: {
      color: COLORS.secondary,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px',
    },
    body2: {
      color: COLORS.grey,
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '24px',
    },
  },
  components: {
    MuiContainer: {},
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          width: '100%',
          '& > .MuiOutlinedInput-notchedOutline': {
            borderRadius: '4px',
            border: '1px solid #2E87CA',
          },
          '& .MuiOutlinedInput-root': {
            padding: 0,
          },
          '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '10px 12px',
          }
        },
        option: {
          fontSize: 14
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {}
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFormHelperText-root': {
            color: COLORS.red
          }
        }
      },
      defaultProps: {
        focused: false,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-switchBase': {
            '& .MuiSwitch-thumb': {
              backgroundColor: '#F3F6F7',
            },
            '& + .MuiSwitch-track': {
              backgroundColor: '#A3ADB5',
            },
            '&.Mui-checked': {
              '& .MuiSwitch-thumb': {
                backgroundColor: '#2E87CA',
              },
              '& + .MuiSwitch-track': {
                backgroundColor: 'rgba(46, 135, 202, .7)',
              },
            },
            '&.Mui-disabled': {
              '& .MuiSwitch-thumb': {
                backgroundColor: '#F3F6F7',
              },
              '& + .MuiSwitch-track': {
                backgroundColor: '#A3ADB5',
              },
            },
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontSize: 13,
          '& .MuiFormControlLabel-label': {
            fontSize: 13,
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#2E87CA',
          // '&.${checkboxClasses.checked}': {
          //     color: 'blue',
          // },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          lineHeight: '20px',
          position: 'relative',
          transform: 'initial',
          marginBottom: '5px',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            color: COLORS.secondaryDark,
            padding: '10px 12px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            top: 0,
            borderRadius: 4,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: 'rgba(163, 173, 181, 0.20)',
            transition: 'border .3s'
          },
          '&:hover': {
            '& > fieldset': {
              borderColor: `${COLORS.primary} !important`
            }
          },
          '& legend': {
            display: 'none'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'OpenSans',
          fontWeight: 500,
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          paddingTop: '0 !important',
        }
      }
    },
  },
});

export default theme;
