import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import AuthSliceReducer from './controllers/AuthSlice';
import UserSliceReducer from './controllers/UserSlice';
import DriverSliceReducer from './controllers/DriverSlice';
import VehicleSliceReducer from './controllers/VehicleSlice';
import CompanySliceReducer from './controllers/CompanySlice';
import ELDSliceReducer from './controllers/ELDSlice';
import AutoUpdateGridReducer from './controllers/AutoUpdateGridSlice';
import LogSlice from './controllers/LogSlice';

import VehicleFiltersSlice from './controllers/VehicleFiltersSlice';
import VehicleMapSlice from './controllers/VehicleMap';
import DailyLogSummarySlice from './controllers/DailyLogSummarySlice';

const rootReducer = combineReducers({
  auth: AuthSliceReducer,
  user: UserSliceReducer,
  driver: DriverSliceReducer,
  vehicle: VehicleSliceReducer,
  company: CompanySliceReducer,
  eld: ELDSliceReducer,
  autoUpdateGrid: AutoUpdateGridReducer,
  vehicleFilters: VehicleFiltersSlice,
  vehicleMap: VehicleMapSlice,
  log: LogSlice,
  dailyLogSummary: DailyLogSummarySlice
});

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export const UseAppDispatch: () => AppDispatch = useDispatch;

export default store;
