import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type DataState = {
  isLoading: boolean;
  isLoadingOdometerUnits: boolean;
  error: any;
  data: any;
  odometerUnits: any;
};

const initialState: DataState = {
  isLoading: false,
  isLoadingOdometerUnits: false,
  error: null,
  data: null,
  odometerUnits: null,
};

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loadUserSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.error = null;
      state.data = action.payload;
    },
    clearUser(state) {
      state.data = null;
    },
    loadUserError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },

    loadOdometerUnits(state, action: PayloadAction<any>) {
      state.odometerUnits = action.payload;
    },
    setLoadingOdometerUnits(state, action: PayloadAction<any>) {
      state.isLoadingOdometerUnits = action.payload;
    },
  },
});

export default UserSlice.reducer;
