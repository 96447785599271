import { LoginResponse } from '@/shared/models/auth.model';
import { UseAppDispatch } from '@/shared/store';
import { logout } from '@/shared/store/actions/AuthActionCreators';
import { loadUser } from '@/shared/store/actions/UserActionCreators';
import { UserSlice } from '@/shared/store/controllers/UserSlice';
import { createContext, useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext({});

export function AuthProvider({ children }: any) {
  const navigate = useNavigate();
  const appDispatch = UseAppDispatch();

  const [cookies, setCookie, removeCookie] = useCookies(['user']);

  useEffect(() => {
    if (cookies?.user) checkExpirationDate();
  }, [navigate]);

  useEffect(() => {
    if (cookies?.user) fetchUser();
  }, [cookies]);

  const checkExpirationDate = () => {
    const { user: { expires } } = cookies || {};
    if (new Date(expires) < new Date()) {
      providerLogout();
    }
  };

  const fetchUser = async () => {
    try {
      await appDispatch(loadUser());
    } catch(err) {
      providerLogout();
    }
  };

  const providerLogin = (userData: LoginResponse) => {
    const { expiry, token } = userData;
    setCookie('user', JSON.stringify({ token, expires: expiry }), { expires: new Date(expiry) });
    navigate('/');
  };

  const providerLogout = async () => {
    removeCookie('user', { path: '/' });
    await appDispatch(logout());
    appDispatch(UserSlice.actions.clearUser());
    localStorage.removeItem('isUserLoggedIn');
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ cookies, providerLogin, providerLogout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
