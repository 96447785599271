import ServiceDialog from '@/shared/UI/dialogs/Service';
import { createContext, useContext, useState } from 'react';

const ServiceDialogContext = createContext({});

const ServiceDialogProvider = ({ children }: any) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<any>({});

  const openDialog = ({ title, message, actionCallback }: any) => {
    setDialogOpen(true);
    setDialogConfig({ title, message, actionCallback });
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig({});
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig.actionCallback(true);
  };

  const onDismiss = () => {
    resetDialog();
    dialogConfig.actionCallback(false);
  };

  return (
    <ServiceDialogContext.Provider value={{ openDialog }}>
      <ServiceDialog
        show={dialogOpen}
        title={dialogConfig?.title}
        message={dialogConfig?.message}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
      />
      {children}
    </ServiceDialogContext.Provider>
  );
};

const useServiceDialog = () => {
  const { openDialog }: any = useContext(ServiceDialogContext);

  const getConfirmation = ({ ...options }) =>
    new Promise((res) => {
      openDialog({ actionCallback: res, ...options });
    });

  return { getConfirmation };
};

export { ServiceDialogProvider, useServiceDialog };