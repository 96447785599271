import { Suspense, lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import Loader from '@/shared/UI/elements/Loader';
import MainLayout from '@/layouts/MainLayout';

const Profile = lazy(() => import('@/pages/Profile'));
const Drivers = lazy(() => import('@/pages/Drivers'));
const Vehicles = lazy(() => import('@/pages/Vehicles'));
const Logs = lazy(() => import('@/pages/Logs'));
const Events = lazy(() => import('@/pages/Events'));
const Dvirs = lazy(() => import('@/pages/Dvirs'));
const Reports = lazy(() => import('@/pages/Reports'));
const Maintenance = lazy(() => import('@/pages/Maintenance'));

const SettingsDrivers = lazy(() => import('@/pages/Settings/Drivers'));
const SettingsVehicles = lazy(() => import('@/pages/Settings/Vehicles'));
const SettingsCompany = lazy(() => import('@/pages/Settings/Company'));
const SettingsELDs = lazy(() => import('@/pages/Settings/ELDs'));

const EditDriver = lazy(() => import('@/pages/Drivers/EditDriver'));
const SettingsAddDriver = lazy(() => import('@/pages/Settings/Drivers/AddDriver'));
const SettingsEditDriver = lazy(() => import('@/pages/Settings/Drivers/EditDriver'));
const SettingsAddVehicle = lazy(() => import('@/pages/Settings/Vehicles/AddVehicle'));
const SettingsEditVehicle = lazy(() => import('@/pages/Settings/Vehicles/EditVehicle'));
const EditCompany = lazy(() => import('@/pages/Settings/Company/EditCompany'));
const EditLog = lazy(() => import('@/pages/Logs/EditLog'));
const VehicleDetails = lazy(() => import('@/pages/Vehicles/Details'));


const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div style={{ width: '100%', height: '100%' }} className="flex items-center justify-center">
            <Loader show={true} />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

const AppPrint = () => {
  return (
    <Suspense
      fallback={
        <div style={{ width: '100%', height: '100%' }} className="flex items-center justify-center">
          <Loader show={true} />
        </div>
      }
    >
      <Outlet />
    </Suspense>
  );
};

export const DrawerMenuTop = [
  {
    name: 'Drivers',
    path: '/drivers',
    element: <Drivers />,
    icon: 'PeopleAltIcon',
  },
  {
    name: 'Vehicles',
    path: '/vehicles',
    element: <Vehicles />,
    icon: 'DirectionsBusFilledIcon',
  },
  {
    name: 'Logs',
    path: '/logs',
    element: <Logs />,
    icon: 'FormatListBulletedIcon',
  },
  {
    name: 'Unidentified Events',
    path: '/events',
    element: <Events />,
    icon: 'CalendarMonthIcon',
  },
  {
    name: 'DVIRs',
    path: '/dvirs',
    element: <Dvirs />,
    icon: 'PlaylistAddCheckIcon',
  },
  {
    name: 'Reports',
    path: '/reports',
    element: <Reports />,
    icon: 'SummarizeIcon',
  },
  {
    name: 'Maintenance',
    path: '/maintenance',
    element: <Maintenance />,
    icon: 'ManageAccountsIcon',
  },
];

export const DrawerMenuBottom = [
  {
    name: 'Drivers',
    path: '/settings-drivers',
    element: <SettingsDrivers />,
    icon: 'PeopleAltIcon',
  },
  {
    name: 'Vehicles',
    path: '/settings-vehicles',
    element: <SettingsVehicles />,
    icon: 'DirectionsBusFilledIcon',
  },
  {
    name: 'Company',
    path: '/settings-company',
    element: <SettingsCompany />,
    icon: 'BusinessIcon',
  },
  {
    name: 'ELDs',
    path: '/settings-elds',
    element: <SettingsELDs />,
    icon: 'SettingsRemoteIcon',
  },
];

const defaultRoutes = [
  { path: '/profile', element: <Profile /> },
  { path: '/drivers/edit-driver/:id', element: <EditDriver /> },
  { path: '/vehicles/:vin/:date', element: <VehicleDetails /> },
  { path: '/settings-drivers/add-driver', element: <SettingsAddDriver /> },
  { path: '/settings-drivers/edit-driver/:id', element: <SettingsEditDriver /> },
  { path: '/settings-vehicles/add-vehicle', element: <SettingsAddVehicle /> },
  { path: '/settings-vehicles/edit-vehicle/:id', element: <SettingsEditVehicle /> },
  { path: '/settings-company/edit-company/:id', element: <EditCompany /> },
  { path: '/logs/edit-log/:id/:date', element: <EditLog /> },
  { path: '/', element: <Navigate to="/drivers" /> },
  { path: '*', element: <Navigate to="/drivers" /> },
];

const printRoutes = [
  { path: 'logs/edit-log/:id/:date', element: <EditLog /> }
];



const routes = [...defaultRoutes, ...DrawerMenuTop, ...DrawerMenuBottom];

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: routes,
  },
  {
    path: '/print',
    element: <AppPrint  />,
    children: printRoutes
  }
];