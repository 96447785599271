import { StorageAutoUpdateValue } from '@/features/TableControls/AutoUpdate';
import { GridKey } from '@/shared/models/grid.model';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type DataState = {
  [key in GridKey]: StorageAutoUpdateValue
};

const initialState: DataState | any = {
  vehicle: 'off',
  vehicleMap: 'off',
  driver: 'off',
  company: 'off',
  elds: 'off',
};

export const AutoUpdateGridSlice = createSlice({
  name: 'autoUpdateGrid',
  initialState,
  reducers: {
    setGridValue(state, action: PayloadAction<any>) {
      const { grid, value } = action.payload;
      state[grid] = value;
    },
  },
});

export default AutoUpdateGridSlice.reducer;
