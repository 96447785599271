import { AppProvider } from './providers';
import { AppRoutes } from '../shared/routes';
import CheckUserProfile from './lib/checkUserProfile';

export const App = () => {
  return (
    <AppProvider>
      <CheckUserProfile />
      <AppRoutes />
    </AppProvider>
  );
};

export default App;