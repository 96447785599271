import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type DataState = {
  error: any;
  elds: any[];

  isLoadingELDs: boolean;
  isRefreshData: boolean;

  dataTotalCount: number;
  dataCurrentCount: number;
};

const initialState: DataState = {
  error: null,
  elds: [],

  isLoadingELDs: false,
  isRefreshData: false,

  dataTotalCount: 0,
  dataCurrentCount: 0,
};

export const ELDSlice = createSlice({
  name: 'eld',
  initialState,
  reducers: {
    loadELDs(state, action: PayloadAction<any>) {
      state.elds = action.payload;
    },
    setLoadingELDs(state, action: PayloadAction<any>) {
      state.isLoadingELDs = action.payload;
    },

    setRefreshData(state, action: PayloadAction<any>) {
      state.isRefreshData = action.payload;
    },

    setTotalCountData(state, action: PayloadAction<any>) {
      state.dataTotalCount = action.payload;
    },
    setCurrentCountData(state, action: PayloadAction<any>) {
      state.dataCurrentCount = action.payload;
    },
  },
});

export default ELDSlice.reducer;
