import { Suspense, lazy, useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import Loader from '@/shared/UI/elements/Loader';
import AuthLayout from '@/layouts/AuthLayout';

const Login = lazy(() => import('@/pages/Auth/Login'));
const Register = lazy(() => import('@/pages/Auth/Register'));
const ForgotPassword = lazy(() => import('@/pages/Auth/ForgotPassword'));
const EmailConfirm = lazy(() => import('@/pages/Auth/ForgotPassword/EmailConfirm'));
const ResetPassword = lazy(() => import('@/pages/Auth/ResetPassword'));
const SetPassword = lazy(() => import('@/pages/Auth/SetPassword'));

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const checkEmailLink = () => {
    const setPasswordURL = '/set-password/';
    const { pathname } = location || {};

    if (pathname && pathname.includes(setPasswordURL)) {
      const token = pathname.split(setPasswordURL);
      if (token?.[1]) {
        navigate(pathname, { state: { token: token[1] } });
      } else {
        navigate('/login');
      }
    }
  };

  useEffect(() => {
    checkEmailLink();
  }, [navigate]);

  return (
    <AuthLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Loader show={true} />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </AuthLayout>
  );
};

export const publicRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'email-confirm', element: <EmailConfirm /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: 'set-password/:u64/:token',  element: <SetPassword />},
      { path: '/',  element: <Navigate to="login" /> },
      { path: '*', element: <Navigate to="login" /> },
    ],
  },
];