import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type DataState = {
  error: any;
  drivers: any[];
  terminals: any[];
  hosRules: any[];
  cargoType: any[];
  restart: any[];
  restBreak: any[];
  driverGroups: any[],
  timeRegulations: any;
  isLoadingDrivers: boolean;
  isLoadingTerminals: boolean;
  isLoadingHosRules: boolean;
  isLoadingCargoType: boolean;
  isLoadingRestart: boolean;
  isLoadingRestBreak: boolean;
  isLoadingDriverGroups: boolean;
  isRefreshData: boolean;
};

const initialState: DataState = {
  error: null,
  drivers: [],
  terminals: [],
  hosRules: [],
  cargoType: [],
  restart: [],
  restBreak: [],
  driverGroups: [],
  timeRegulations: null,
  isLoadingDrivers: false,
  isLoadingDriverGroups: false,
  isLoadingTerminals: false,
  isLoadingHosRules: false,
  isLoadingCargoType: false,
  isLoadingRestart: false,
  isLoadingRestBreak: false,
  isRefreshData: false,
};

export const DriverSlice = createSlice({
  name: 'driver',
  initialState,
  reducers: {
    loadDrivers(state, action: PayloadAction<any>) {
      state.drivers = action.payload;
    },
    setLoadingDrivers(state, action: PayloadAction<any>) {
      state.isLoadingDrivers = action.payload;
    },

    loadTerminals(state, action: PayloadAction<any>) {
      state.terminals = action.payload;
    },
    setLoadingTerminals(state, action: PayloadAction<any>) {
      state.isLoadingTerminals = action.payload;
    },

    loadHosRules(state, action: PayloadAction<any>) {
      state.hosRules = action.payload;
    },
    setLoadingHosRules(state, action: PayloadAction<any>) {
      state.isLoadingHosRules = action.payload;
    },

    loadDriverGroups(state, action: PayloadAction<any>) {
      state.driverGroups = action.payload;
    },
    setLoadingDriverGroups(state, action: PayloadAction<any>) {
      state.isLoadingDriverGroups = action.payload;
    },

    loadCargoType(state, action: PayloadAction<any>) {
      state.cargoType = action.payload;
    },
    setLoadingCargoType(state, action: PayloadAction<any>) {
      state.isLoadingCargoType = action.payload;
    },

    loadRestart(state, action: PayloadAction<any>) {
      state.restart = action.payload;
    },
    setLoadingRestart(state, action: PayloadAction<any>) {
      state.isLoadingRestart = action.payload;
    },

    loadRestBreak(state, action: PayloadAction<any>) {
      state.restBreak = action.payload;
    },
    setLoadingRestBreak(state, action: PayloadAction<any>) {
      state.isLoadingRestBreak = action.payload;
    },

    loadTimeRegulations(state, action: PayloadAction<any>) {
      state.timeRegulations = action.payload;
    },

    setRefreshData(state, action: PayloadAction<any>) {
      state.isRefreshData = action.payload;
    },
  },
});

export default DriverSlice.reducer;
