import { useRoutes } from 'react-router-dom';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import { useAuth } from '@/app/providers/auth';

export const AppRoutes = () => {
  const { cookies }: any = useAuth();

  const routes = cookies?.user ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes]);

  return <>{element}</>;
};