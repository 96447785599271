import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type DataState = {
  records: any[];
  isRefreshData: boolean;
};

const initialState: DataState = {
  records: [],
  isRefreshData: false,
};

export const VehicleMapSlice = createSlice({
  name: 'vehicleMap',
  initialState,
  reducers: {
    loadVehicles(state, action: PayloadAction<any>) {
      state.records = action.payload;
    },
    setRefreshData(state, action: PayloadAction<any>) {
      state.isRefreshData = action.payload;
    },
  },
});

export default VehicleMapSlice.reducer;
