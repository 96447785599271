import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const FilterSortData = [
  {
    value: '0',
    label: 'Sort by Time'
  },
  {
    value: '1',
    label: 'Sort by Vehicle'
  }
];

export const FilterOrderData = [
  {
    value: '0',
    label: 'Desc.'
  },
  {
    value: '1',
    label: 'Asc.'
  }
];

export type DataState = {
  vehicleValue: any;
  driverValue: any;
  sortValue: any;
  orderValue: any;
};

const initialState: DataState = {
  vehicleValue: null,
  driverValue: null,
  sortValue: FilterSortData[0]?.value,
  orderValue: FilterOrderData[0]?.value,
};

export const VehicleFiltersSlice = createSlice({
  name: 'vehicleFilters',
  initialState,
  reducers: {
    setVehicleValue(state, action: PayloadAction<any>) {
      state.vehicleValue = action.payload;
    },
    setDriverValue(state, action: PayloadAction<any>) {
      state.driverValue = action.payload;
    },
    setSortValue(state, action: PayloadAction<any>) {
      state.sortValue = action.payload;
    },
    setOrderValue(state, action: PayloadAction<any>) {
      state.orderValue = action.payload;
    },
  },
});

export default VehicleFiltersSlice.reducer;
