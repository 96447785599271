import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Collapse, Container, Grid, Link, Stack, useMediaQuery } from '@mui/material';
import AppLogo from '@/shared/UI/elements/Logo';
import { DrawerMenuTop, DrawerMenuBottom } from '@/shared/routes/protected';
import IconButton from '@mui/material/IconButton';

import theme, { COLORS } from '@/app/providers/theme';
import { MainLayoutContainer, MenuLink } from '@/shared/UI/styled/global';

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DirectionsBusFilledIcon from '@mui/icons-material/DirectionsBusFilled';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import BusinessIcon from '@mui/icons-material/Business';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import MenuIcon from '@mui/icons-material/Menu';

import HeaderAccount from '@/features/Profile/HeaderAccount';
import { ExpandLess, ExpandMore } from '@mui/icons-material';


interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const drawerWidth = 240;

const MenuIcons: any = {
  'PeopleAltIcon': <PeopleAltIcon sx={{ fontSize: 24, color: COLORS.grey }} />,
  'DirectionsBusFilledIcon': <DirectionsBusFilledIcon sx={{ fontSize: 24, color: COLORS.grey }} />,
  'FormatListBulletedIcon': <FormatListBulletedIcon sx={{ fontSize: 24, color: COLORS.grey }} />,
  'CalendarMonthIcon': <CalendarMonthIcon sx={{ fontSize: 24, color: COLORS.grey }} />,
  'PlaylistAddCheckIcon': <PlaylistAddCheckIcon sx={{ fontSize: 24, color: COLORS.grey }} />,
  'SummarizeIcon': <SummarizeIcon sx={{ fontSize: 24, color: COLORS.grey }} />,
  'ManageAccountsIcon': <ManageAccountsIcon sx={{ fontSize: 24, color: COLORS.grey }} />,
  'SettingsIcon': <SettingsIcon sx={{ fontSize: 24, color: COLORS.grey }} />,
  'BusinessIcon': <BusinessIcon sx={{ fontSize: 24, color: COLORS.grey }} />,
  'SettingsRemoteIcon': <SettingsRemoteIcon sx={{ fontSize: 24, color: COLORS.grey }}/>,
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    width: `calc(100% - ${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${theme.spacing(9)} + 1px)`,
    },
  })
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const DrawerListItem = ({ name, path, icon, idx, open }: any) => {
  return (
    <ListItem
      key={idx}
      disablePadding
      sx={{ display: 'block' }}
    >
      <ListItemButton
        sx={{
          padding: '0 24px',
          justifyContent:
          open ? 'initial' : 'center',
          '&:hover, a.active': {
            'p, svg': {
              color: theme.palette.primary.main,
              fill: theme.palette.primary.main,
            },
          },
          [theme.breakpoints.down('sm')]: {
            padding: '0 15px',
          },
        }}
      >
        <MenuLink to={path}>
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            {MenuIcons?.[icon]}
          </ListItemIcon>
          <Typography
            variant="body2"
            fontSize={14}
            sx={{ display: open ? 'block' : 'none', flexGrow: 1 }}>
            {name}
          </Typography>
        </MenuLink>
      </ListItemButton>
    </ListItem>
  );
};

export default function MiniDrawer({ children }: any) {
  const theme = useTheme();
  const mediaQueryUpSM = useMediaQuery(theme.breakpoints.up(800));

  const [open, setOpen] = React.useState(mediaQueryUpSM);
  const [openSettingsMenu, setOpenSettingsMenu] = React.useState(false);

  React.useEffect(() => {
    setOpen(mediaQueryUpSM);
  }, [mediaQueryUpSM]);

  const handleDrawerMenu = () => {
    setOpen(!open);
  };

  const handleClickSettingsMenu = () => {
    setOpenSettingsMenu(!openSettingsMenu);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          padding: '16px',
          ['@media print']: {
            display: 'none'
          }
        }}>
        <DrawerHeader
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            padding: '16px',
            [theme.breakpoints.down('sm')]: {
              padding: '9px',
            },
          }}>
          <Link
            href="/"
            sx={{
              margin: '0 0 20px',
              padding: 0,
              width: open ? 'auto' : '36px',
              overflow: open ? 'auto' : 'hidden',
              'img': {
                minWidth: '124px'
              },
            }}>
            <AppLogo width={124} height={36} />
          </Link>
        </DrawerHeader>
        <List>
          {DrawerMenuTop.map(({ name, path, icon }, idx) =>
            <DrawerListItem
              key={idx}
              name={name}
              path={path}
              icon={icon}
              idx={idx}
              open={open}
            />
          )}
          <Divider />
          <ListItem sx={{ padding: 0 }} onClick={handleClickSettingsMenu}>
            <ListItemButton
              sx={{
                padding: '16px 24px',
                [theme.breakpoints.down('sm')]: {
                  padding: '16px 8px',
                },
              }}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {MenuIcons?.['SettingsIcon']}
              </ListItemIcon>
              <Typography
                variant="body2"
                fontSize={14}
                sx={{ display: open ? 'block' : 'none', flexGrow: 1 }}>
                Settings
              </Typography>
              {openSettingsMenu ? <ExpandLess sx={{ color: COLORS.grey }} /> : <ExpandMore sx={{ color: COLORS.grey }} />}
            </ListItemButton>
          </ListItem>
          <Collapse in={openSettingsMenu} timeout="auto" unmountOnExit>
            <List
              component="div"
              sx={{
                paddingLeft: '15px',
                [theme.breakpoints.down('sm')]: {
                  paddingLeft: '8px',
                },
              }}>
              {DrawerMenuBottom.map(({ name, path, icon }, idx) =>
                <DrawerListItem
                  key={idx}
                  name={name}
                  path={path}
                  icon={icon}
                  idx={idx}
                  open={open}
                />
              )}
            </List>
          </Collapse>
        </List>
      </Drawer>
      <Box component="main" sx={{
        flexGrow: 1,
        position: 'relative',
        height: '100vh',
        background: COLORS.grey1
      }}>
        <AppBar position="fixed" open={open} sx={{
          ['@media print']: {
            display: 'none'
          }
        }}>
          <Toolbar sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerMenu}
              edge="start"
              sx={{
                marginRight: 5,
              }}
            >
              <MenuIcon sx={{ fontSize: 24 }} />
            </IconButton>
            <HeaderAccount />
          </Toolbar>
        </AppBar>
        <DrawerHeader sx={{
          ['@media print']: {
            display: 'none !important'
          }
        }} />
        <MainLayoutContainer container>
          {children}
        </MainLayoutContainer>
      </Box>
    </Box>
  );
}