import axios from 'axios';
import { keysToCamel, keysToSnake } from '@/shared/helpers/transformKeys';
import { getCookie } from '@/shared/helpers/getCookie';

const baseURL = process.env.REACT_APP_GS_FLY_PUBLIC_BACKEND_URL;
const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(async config => {
  const accessToken = getCookie('user', 'token');
  if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
  if (config.data) config.data = keysToSnake(config.data);
  return config;
});

axiosInstance.interceptors.response.use(
  (response: any) => {
    if (response.success || response?.status === 200 || response?.status === 201) {
      if (!response.config.responseType || response.config.responseType === 'json') {
        return keysToCamel(response.data);
      }
      return response;
    } else {
      throw new Error(response.data);
    }
  },
  (error) => {
    let message = 'Something went wrong. Please try again later.';
    if (error?.response) {
      // The request was made and the server responded with a status code
      console.error(
        `Status: ${error.response.status}`,
        `\nUrl: ${error.response.config.baseURL}/${error.response.config.url}`,
        `\nMessage: ${error.message}`,
        `\nData: ${JSON.stringify(error.response.data)}`,
      );

      if (error?.response?.data) {
        if (error?.response?.status === 400) {
          if (typeof error?.response?.data == 'object') {
            message = `${Object.values(error?.response?.data)}`;
          } else {
            message = error?.response?.data || message;
          }
        } else {
          message = error.response.data.message || message;
        }
      }

    } else if (error?.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error', error.message);
      message = error.message;
    }

    throw new Error(message);
  },
);

export default axiosInstance;
