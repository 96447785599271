import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Menu, MenuItem, Typography } from '@mui/material';
import styled from '@emotion/styled';

import theme, { COLORS } from '@/app/providers/theme';
import { useAuth } from '@/app/providers/auth';

import PersonIcon from '@mui/icons-material/Person';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Logout } from '@mui/icons-material';
import { useSelector } from 'react-redux';


const HeaderAccount = () => {
  const navigate = useNavigate();
  const { providerLogout }: any = useAuth();

  const user = useSelector((state: any) => state.user.data);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleLogout = async () => {
    handleClose();
    providerLogout();
  };

  const handleNavigate = (path: string) => {
    handleClose();
    navigate(path);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderUsername = (): string => {
    let username = 'Test Account';
    if (user) {
      if (user?.firstName !== '' || user?.lastName !== '') {
        username = `${user?.firstName || ''} ${user?.lastName || ''}`;
      } else {
        username = `${user?.username}`;
      }
    }

    return username;
  };

  const renderTextAvatar = (): string => {
    const username = renderUsername();
    let textAvatar = '';

    const usernameUnit = username.split(' ');

    if (usernameUnit.length === 1) {
      textAvatar = usernameUnit[0].substring(0, 2);
    } else if (usernameUnit.length >= 2) {
      textAvatar = `${usernameUnit[0].substring(0, 1)}${usernameUnit[1].substring(0, 1)}`;
    }

    return textAvatar.toUpperCase();
  };

  return (
    <>
      <Box
        onClick={handleClick}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          ml: 2,
          cursor: 'pointer'
        }}>
        <AccountAvatar>{renderTextAvatar()}</AccountAvatar>
        <Box
          sx={{
            color: COLORS.white,
            textAlign: 'left',
            position: 'relative',
            pr: '30px',
            pl: '18px'
          }}>
          <Typography variant='body2' sx={{ color: COLORS.white }}>
            {renderUsername()}
          </Typography>
          <ArrowDropDownIcon
            sx={{
              fontSize: 22,
              color: 'rgba(243, 246, 247, 0.20)',
              position: 'absolute',
              right: 0,
              top: '2px',
            }}
          />
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            minWidth: 196,
            borderRadius: '8px',
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
            '& .MuiList-root': {
              p: '8px',
            },
            'li': {
              minHeight: '40px',
              p: '8px'
            }
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => handleNavigate('/profile')}>
          <PersonIcon sx={{ fontSize: 24, color: COLORS.grey, mr: '24px' }}  />
          Account
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Logout sx={{ fontSize: 24, color: COLORS.grey, mr: '24px' }} />
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export const AccountAvatar = styled(Avatar)(() => ({
  width: 40,
  height: 40,
  color: theme.palette.background.paper,
  fontSize: 14,
  backgroundColor: 'rgba(0, 0, 0, 0.20)',
  border: '1px solid rgba(243, 246, 247, 0.20)',
  borderRadius: '50%',
}));

export default HeaderAccount;
