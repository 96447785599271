import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { PrimaryButton, SecondaryButton } from '../styled/global';

interface AlertProps {
  title?: string | null;
  message?: string | null;
  show: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
}

const ServiceDialog = ({
  title = '',
  message = '',
  show = false,
  onConfirm,
  onDismiss,
}: AlertProps) => {
  return (
    <Dialog
      open={show}
      onClose={onDismiss}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          fontSize: 16
        }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          sx={{
            marginRight: '10px',
          }}
          style={{ padding: '7px 20px' }}
          type="button"
          onClick={onDismiss}>
          Disagree
        </SecondaryButton>
        <PrimaryButton
          style={{ padding: '7px 20px' }}
          type="button"
          onClick={onConfirm}>
          Agree
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};


export default ServiceDialog;
