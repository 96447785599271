import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { UserAccount } from '@/shared/models/user.model';
import { toast } from 'react-toastify';

const StorageIsUserLoggedInKey = 'isUserLoggedIn';

type UserLoggedIn = { isLoggedIn: boolean };

export const CheckUserProfile = () => {
  const navigate = useNavigate();

  const user: UserAccount | undefined = useSelector((state: any) => state.user.data);

  useEffect(() => {
    if (user) {
      checkProfileInfo(user);
    }
  }, [user]);

  const checkProfileInfo = (user: UserAccount | undefined) => {
    const value = localStorage.getItem(StorageIsUserLoggedInKey);
    const parseValue: UserLoggedIn = value ? JSON.parse(value) : {};
    if (!parseValue.isLoggedIn && (!user?.firstName || !user?.lastName)) {
      navigate('/profile');
      toast('Please, fill in your first and last name.');
      localStorage.setItem('isUserLoggedIn', JSON.stringify({ isLoggedIn: true }));
    }
  };

  return null;
};

export default CheckUserProfile;