import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type DataState = {
  isLoading: boolean;
  isLoadingTimezone: boolean;
  isRefreshData: boolean;
  error: any;
  data: any;
  timezone: any;
};

const initialState: DataState = {
  error: null,
  data: null,
  timezone: null,
  isLoading: false,
  isLoadingTimezone: false,
  isRefreshData: false,
};

export const CompanySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    loadCompanies(state, action: PayloadAction<any>) {
      state.data = action.payload;
    },
    setLoadingCompanies(state, action: PayloadAction<any>) {
      state.isLoading = action.payload;
    },
    setErrorCompanies(state, action: PayloadAction<any>) {
      state.error = action.payload;
    },
    loadTimezone(state, action: PayloadAction<any>) {
      state.timezone = action.payload;
    },
    setLoadingTimezone(state, action: PayloadAction<any>) {
      state.isLoadingTimezone = action.payload;
    },
    setRefreshData(state, action: PayloadAction<any>) {
      state.isRefreshData = action.payload;
    },
  },
});

export default CompanySlice.reducer;
